import axios from 'axios';
import Router from 'next/router'

export interface IGetRequest {
  path: string
}

export const get = async ({ path }: IGetRequest) => {
  try {
    return await axios.get(path, { headers: { 'Authorization': localStorage.getItem('snapsmile_core_jwt') || '' } })
  } catch (error: any) {
    console.log(error)
    if(error.response) {
      logError(error.response)
      if(error.response.status === 401)
        redirectToLogin()
    }
    throw error
  }
}

export interface IPostRequest {
  path: string
  data?: object
}

export const post = async ({ path, data = {} }: IPostRequest) => {
  try {
    return await axios.post(path, data, { headers: { 'Authorization': localStorage.getItem('snapsmile_core_jwt') || '' } })
  } catch (error: any) {
    console.log(error)
    if(error.response) {
      if(error.response.status === 401)
        redirectToLogin()
    }
    throw error
  }
}

export interface IPatchRequest {
  path: string
  data?: object
}

export const patch = async ({ path, data = {} }: IPatchRequest) => {
  try {
    return await axios.patch(path, data, { headers: { 'Authorization': localStorage.getItem('snapsmile_core_jwt') || '' } })
  } catch (error: any) {
    console.log(error)
    if(error.response) {
      if(error.response.status === 401)
        redirectToLogin()
    }
    throw error
  }
}

const logError = (response: any) => {
  // console.log(response.data)
  // console.log(response.status)
  // console.log(response.headers)
}

const redirectToLogin = () => {
  localStorage.clear()
  Router.push('/api/auth/login')
}
