import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUser } from '@auth0/nextjs-auth0/client';
import { get } from '../networking/authenticatedRequests';
import Endpoints from '../networking/Endpoints';

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #F3F3F3;
`

export async function getServerSideProps(context: any) {
  return {
      props: {},
  };
}

const Home = () => {
  const router = useRouter()
  const { user, isLoading, error } = useUser()
  const [currentRequest, setCurrentRequest] = useState<any>(null)

  useEffect(() => {

  }, [])

  useEffect(() => {

    if(router.query.action === 'logout') {
      localStorage.clear()
    }
  }, [])

  useEffect(() => {
    if (isLoading) return;

    if(user) {
      setTimeout(() => {
        get({ path: Endpoints.users.me }).then((response) => {
          get({ path: Endpoints.inPersonBooking.requests.currentRequest }).then((response) => {
            setCurrentRequest(response.data)

            if(response.data.state !== 'pending' && response.data.state !== 'form_in_progress') {
              router.push('/in-person-booking-form/appointment-requested')
            } else {
              router.push('/in-person-booking-form/reward-selection')
            }
          }).catch((error) => {
            alert('An error occured fetching the current request')
            console.log(error)
            router.push('/in-person-booking-form/reward-selection')
          })
        }).catch((error) => {
          router.push(`/auth/create-account?initiate_booking_flow=${ true }`)
        })
      }, 400)
    } else {
      router.push('/web-lead-login')
    }
  }, [user, error, isLoading])

  return(
    <MainContainer>
    </MainContainer>
  )
}

export default Home
