// const baseUrl: string = process.env.NEXT_PUBLIC_SNAPSMILE_CORE_API_URL || 'http://localhost:3000/api/v1'
// const baseUrl: string = process.env.NEXT_PUBLIC_SNAPSMILE_CORE_API_URL || 'https://snapsmile-core-staging.herokuapp.com/api/v1'
const baseUrl: string = process.env.NEXT_PUBLIC_SNAPSMILE_CORE_API_URL || 'https://snapsmile-core.herokuapp.com/api/v1'

const Endpoints =  {
  authorize:     `${ baseUrl }/authorize`,
  createAccount: `${ baseUrl }/create_account`,
  users: {
    me: `${ baseUrl }/users/me`,
  },
  inPersonBooking: {
    requests: {
      currentRequest: `${ baseUrl }/in_person_booking/requests/current_request`,
      updateForm:                (requestId: string) => { return `${ baseUrl }/in_person_booking/requests/${ requestId }/update_form` },
      confirmDentistAndComplete: (requestId: string) => { return `${ baseUrl }/in_person_booking/requests/${ requestId }/confirm_dentist_and_complete` },
    },
    facebookLeadForms: {
      validateUuid: (uuid: string) => { return `${ baseUrl }/in_person_booking/facebook_lead_forms/validate_uuid?uuid=${ uuid }` },
      linkToExistingUser: `${ baseUrl }/in_person_booking/facebook_lead_forms/link_to_existing_user`
    }
  },
  dentalLocations: {
    nearMe: (postalCode: string) => { return `${ baseUrl }/dental_locations/near_me?postal_code=${ postalCode }` },
    nearMeOpen: (postalCode: string) => { return `${ baseUrl }/dental_locations/near_me_open?postal_code=${ postalCode }` },
  },
  intakeForms: {
    startForm:              `${ baseUrl }/intake_forms/start_form`,
    update:                 (uuid: string)               => { return `${ baseUrl }/intake_forms/${ uuid }` },
    submitQuestionFeedback: (uuid: string)               => { return `${ baseUrl }/intake_forms/${ uuid }/submit_question_feedback` },
    submitFeedback:         (uuid: string)               => { return `${ baseUrl }/intake_forms/${ uuid }/submit_feedback` },
    imageUploadUrl:         (uuid: string, type: string) => { return `${ baseUrl }/intake_forms/${ uuid }/image_upload_url?type=${ type }` },
    uploadedImage:          (uuid: string, type: string) => { return `${ baseUrl }/intake_forms/${ uuid }/uploaded_image?type=${ type }` },
    complete:               (uuid: string)               => { return `${ baseUrl }/intake_forms/${ uuid }/complete` }
  },
  addressAutocomplete: (query: string)   => { return `${ baseUrl }/clinics/address_autocomplete?search_string=${ query }` },
  addressDetails:      (placeId: string) => { return `${ baseUrl }/clinics/address_details?place_id=${ placeId }` }
}

export default Endpoints
